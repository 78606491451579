
import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import municipios from "@/data/municipios";
import PartnerService, {
  CreatePartnerParams,
  PartnerContactTypeList
} from "@/services/partner-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import PartnerRegistrationHeader from "@/components/partner-registration/PartnerRegistrationHeader.vue";
import PartnerRegistrationFooter from "@/components/partner-registration/PartnerRegistrationFooter.vue";
import SaveButton from "@/components/SaveButton.vue";
import UploadDialog from "@/components/UploadDialog.vue";
import TermsDialog from "@/components/TermsDialog.vue";
import AlertBox from "@/components/AlertBox.vue";
import PartnerRegistrationEventBus from "@/events/partner-registration-event-bus";
import store from "@/store";
import PartnerContact from "@/types/partner-contact";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

@Component({
  components: {
    ValidationObserver,
    PartnerRegistrationHeader,
    PartnerRegistrationFooter,
    UploadDialog,
    TermsDialog,
    SaveButton,
    AlertBox
  }
})
export default class PartnerData extends Vue {
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  partnerContactTypes: PartnerContactTypeList | null = null;
  cities: string[] = [];
  states = municipios.estados;
  loading: boolean = false;
  acceptTerms: boolean = false;
  showTermsError: boolean = false;
  documentAlreadyExists: boolean = false;
  documentInvalid: boolean = false;
  form: Omit<CreatePartnerParams, "user"> = {
    name: "",
    document: "",
    email: "",
    password: "",
    userId: this.authenticatedUser?.id,
    partnerStatusId: 1,
    address: {
      name: "",
      address: "",
      number: "",
      complement: "",
      district: "",
      city: "",
      state: "",
      zipCode: ""
    },
    contacts: [],
    identifier: {
      identifier: "",
      name: ""
    },
    comission: {
      comissionPercentage: "1"
    }
  };

  partnerService: PartnerService;

  emptyContact = {
    email: "",
    mobilePhoneWithAreaCode: "",
    typeId: 1,
    phone: "",
    phoneExtension: ""
  } as PartnerContact;

  constructor() {
    super();
    this.partnerService = PartnerService.getInstance();
  }

  mounted(): void {
    this.fetchPartnerContactTypeList();
    this.loadCities();
    if (this.form.contacts && this.form.contacts?.length === 0) {
      this.form.contacts?.push({ ...this.emptyContact });
    }
  }

  async fetchPartnerContactTypeList(): Promise<void> {
    const [error, partnerContactTypes] =
      await this.partnerService.listPartnerContactTypes();
    if (error || !partnerContactTypes) {
      this.$notify({
        type: "error",
        text: "Não foi possível obter a lista de status"
      });
    } else {
      this.partnerContactTypes = partnerContactTypes;
    }
  }

  loadCities(): void {
    if (this.form.address?.state) {
      this.cities = municipios.cidadePorEstado(this.form.address?.state);
    } else {
      this.cities = [];
    }
    if (
      this.form.address?.city &&
      !this.cities.includes(this.form.address?.city)
    ) {
      this.form.address.city = "";
    }
  }

  addContact(): void {
    const contacts = this.form.contacts || [];
    contacts.push({ ...this.emptyContact });
    this.form.contacts = contacts;
  }

  removeContact(index: number): void {
    if (this.form.contacts) {
      this.form.contacts.splice(index, 1);
    }
  }

  async validateDocument(): Promise<void> {
    this.documentAlreadyExists = false;
    this.documentInvalid = false;
    if (
      this.form.document &&
      (this.form.document.length === 18 || this.form.document.length === 14)
    ) {
      this.loading = true;
      // Update existing partner
      const [error, alreadyExists] = await this.partnerService.validateDocument(
        this.form.document
      );

      this.loading = false;

      if (error) {
        this.documentInvalid = true;
      } else {
        if (alreadyExists) {
          this.documentAlreadyExists = true;
        }
      }
    }
  }

  async save(): Promise<void> {
    if (this.acceptTerms) {
      this.startLoading();

      let partnerError, partner;

      [partnerError, partner] = await this.partnerService.createPartner(
        this.form
      );

      if (!partnerError) {
        this.stopLoading();
        this.goToNextStep();
      } else {
        this.stopLoading();
        const message = getErrorMessageFromApiError(partnerError);
        this.$notify({
          title: "Erro ao registrar parceiro",
          type: "error",
          text: message
        });
      }
    } else {
      this.showTermsError = true;
    }
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  goToNextStep(): void {
    PartnerRegistrationEventBus.$emit(
      "partnerRegistrationState",
      "waitForApproval"
    );
  }

  goToLogin(): void {
    this.$router.push("/entrar");
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  get identifierUrl(): string {
    if (this.form && this.form.identifier?.identifier) {
      return `${process.env.VUE_APP_ADMIN_PORTAL_URL}/cadastrar-empresa?partner=${this.form.identifier.identifier}`;
    }
    return "";
  }

  async copy(value: string): Promise<void> {
    await navigator.clipboard.writeText(value);
    this.$notify({ type: "success", text: "Copiado com sucesso!" });
  }

  generateIdentifier(): void {
    if (this.form.name && !this.form.identifier.identifier) {
      this.form.identifier.identifier = this.form.name
        .toLowerCase()
        .split(" ")[0];
    }
  }
}
