
import { Vue, Component, Prop } from "vue-property-decorator";
import SignIn from "@/components/auth/SignIn.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import ExpiredPassword from "@/components/auth/ExpiredPassword.vue";
import RequireNewPassword from "@/components/auth/RequireNewPassword.vue";
import SignOut from "@/components/auth/SignOut.vue";
import ConfirmSignUp from "@/components/auth/ConfirmSignUp.vue";
import AmplifyEventBus from "@/events/amplify-event-bus";

@Component({
  components: {
    SignIn,
    ForgotPassword,
    ConfirmSignUp,
    ExpiredPassword,
    RequireNewPassword,
    SignOut
  }
})
export default class Authenticator extends Vue {
  @Prop() readonly authConfig: any;

  displayMap = {};
  user = {
    email: null
  };

  constructor() {
    super();
  }

  mounted() {
    AmplifyEventBus.$on("localUser", (user: any) => {
      this.user = user;
      this.options.signInConfig.email = this.user.email;
      this.options.confirmSignInConfig.user = this.user;
      this.options.confirmSignUpConfig.email = this.user.email;
      this.options.expiredPasswordConfig.email = this.user.email;
      this.options.requireNewPasswordConfig.user = this.user;
      this.options.forgotPasswordConfig.email = this.user.email;
    });

    AmplifyEventBus.$on("authState", (data: string) => {
      this.displayMap = this.updateDisplayMap(data);
    });

    //initial state
    AmplifyEventBus.$emit("authState", "signIn");
  }

  updateDisplayMap(state: string) {
    return {
      showSignIn: state === "signIn",
      showForgotPassword: state === "forgotPassword",
      expiredPassword: state === "expiredPassword",
      requireNewPassword: state === "newPassword",
      showSignOut: state === "signOut",
      showConfirmSignUp: state === "confirmSignUp"
    };
  }

  get options() {
    const defaults = {
      signInConfig: {},
      confirmSignUpConfig: {},
      confirmSignInConfig: {},
      forgotPasswordConfig: {},
      requireNewPasswordConfig: {},
      expiredPasswordConfig: {},
      emailAttributes: "email"
    };
    return Object.assign(defaults, this.authConfig || {});
  }
}
