
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import AuthLayout from "@/components/core/layouts/AuthLayout.vue";
import { mapGetters } from "vuex";
import AmplifyEventBus from "@/events/amplify-event-bus";
import { INVALID_PASSWORD_DEFAULT_MESSAGE } from "@/utils/isValidPassword";

@Component({
  components: { ValidationObserver, AuthLayout },
  computed: {
    ...mapGetters({ isAuthenticated: "auth/isAuthenticated" })
  }
})
export default class RequireNewPassword extends Vue {
  @Prop() requireNewPasswordConfig: any;

  INVALID_PASSWORD_DEFAULT_MESSAGE = INVALID_PASSWORD_DEFAULT_MESSAGE;

  loading: boolean;
  email: string;
  password: string;
  newPassword: string;
  isAuthenticated!: boolean;
  requiredAttributes = {};

  constructor() {
    super();
    this.email = "";
    this.password = "";
    this.newPassword = "";
    this.loading = false;
  }

  async handleCompleteNewPassword() {
    this.loading = true;
    await this.$store
      .dispatch("auth/completeNewPassword", {
        user: this.options.user,
        password: this.password,
        newPassword: this.newPassword,
        requiredAttributes: this.requiredAttributes
      })
      .then(() => {
        this.$notify({
          type: "success",
          text: "Informações atualizadas com sucesso."
        });
        AmplifyEventBus.$emit("authState", "signIn");
      })
      .catch((e) => {
        this.$notify({
          type: "error",
          text: "Erro ao atualizar informações."
        });
      });

    this.loading = false;
  }

  get options() {
    const defaults = {
      user: {
        challengeParam: {
          requiredAttributes: []
        }
      }
    };
    return Object.assign(defaults, this.requireNewPasswordConfig || {});
  }

  goToSignIn() {
    AmplifyEventBus.$emit("authState", "signIn");
  }
}
