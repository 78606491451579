
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import AuthLayout from "@/components/core/layouts/AuthLayout.vue";
import CaptchaDisclaimer from "@/components/CaptchaDisclaimer.vue";
import { mapGetters } from "vuex";
import AmplifyEventBus from "@/events/amplify-event-bus";
import { INVALID_PASSWORD_DEFAULT_MESSAGE } from "@/utils/isValidPassword";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { VueRecaptcha } from "vue-recaptcha";
import { CognitoUserStatusEnum } from "@/types/cognito-user-status-enum";

@Component({
  components: {
    ValidationObserver,
    AuthLayout,
    CaptchaDisclaimer,
    VueRecaptcha
  },
  computed: {
    ...mapGetters({ isAuthenticated: "auth/isAuthenticated" })
  }
})
export default class ForgotPassword extends Vue {
  @Prop() forgotPasswordConfig: any;
  CognitoUserStatusEnum = CognitoUserStatusEnum;
  INVALID_PASSWORD_DEFAULT_MESSAGE = INVALID_PASSWORD_DEFAULT_MESSAGE;

  $refs!: {
    form: HTMLFormElement;
    obs: HTMLFormElement;
    recaptchaForgotPassword: HTMLFormElement;
    recaptchaForgotPasswordSubmit: HTMLFormElement;
  };

  loading: boolean;
  email: string;
  password: string;
  passwordConfirmation: string;
  verificationCode: string;
  sent: boolean;
  isAuthenticated!: boolean;
  reCaptchaSiteKey = String(process.env.VUE_APP_RECAPTCHA_SITE_KEY);

  constructor() {
    super();
    this.email = "";
    this.password = "";
    this.passwordConfirmation = "";
    this.verificationCode = "";
    this.sent = false;
    this.loading = false;
  }

  mounted() {
    if (this.isFromOptions) {
      this.onSubmitForgotPassword();
    }
  }

  handleSubmit() {
    if (this.isFromOptions) this.onSubmitForgotPasswordSubmit();
    else {
      this.onSubmitForgotPassword();
    }
  }

  onSubmitForgotPasswordSubmit(): void {
    this.$refs.recaptchaForgotPasswordSubmit.execute();
  }

  onVerifyForgotPasswordSubmit(captchaToken: string): void {
    if (captchaToken) {
      this.handleForgotPasswordSubmit(captchaToken);
    }
  }

  onExpiredForgotPasswordSubmit(): void {
    this.$refs.recaptchaForgotPasswordSubmit.reset();
  }

  onErrorForgotPasswordSubmit(): void {
    this.$refs.recaptchaForgotPasswordSubmit.reset();
  }

  onVerifyForgotPassword(captchaToken: string): void {
    if (captchaToken) {
      this.handleForgotPassword(captchaToken);
    }
  }

  onExpiredForgotPassword(): void {
    this.$refs.recaptchaForgotPassword.reset();
  }

  onErrorForgotPassword(): void {
    this.$refs.recaptchaForgotPassword.reset();
  }

  onSubmitForgotPassword(): void {
    this.$refs.recaptchaForgotPassword.execute();
  }

  async handleForgotPassword(captchaToken: string) {
    this.loading = true;
    await this.$store
      .dispatch("auth/forgotPassword", {
        email: this.options.email,
        captchaToken
      })
      .then((data) => {
        if (
          data?.cognitoUserStatus === CognitoUserStatusEnum.FORCE_CHANGE_PASSWORD
        ) {
          this.$notify({
            type: "success",
            title: "Senha temporária enviada",
            text: "Uma senha temporária foi enviada para o seu e-mail. Utilize-a para fazer o login.",
            duration: 10000
          });
          AmplifyEventBus.$emit("authState", "signIn");
        } else {
          this.$notify({
            title: "Código de recuperação enviado",
            type: "success",
            text: "Um código para recuperação de senha foi enviado para o seu e-mail. Utilize-o para alterar sua senha.",
            duration: 10000
          });
        }

        this.sent = true;
      })
      .catch((e) => {
        return this.$notify({
          type: "error",
          text:
            "Erro ao enviar e-mail de recuperação de senha: " +
              e?.response?.data?.message || ""
        });
      })
      .finally(() => {
        this.loading = false;
        this.$refs.recaptchaForgotPassword?.reset();
      });
  }

  async handleForgotPasswordSubmit(captchaToken: string) {
    this.loading = true;
    await this.$store
      .dispatch("auth/forgotPasswordSubmit", {
        email: this.options.email,
        verificationCode: this.verificationCode,
        password: this.password,
        captchaToken
      })
      .then(() => {
        AmplifyEventBus.$emit("authState", "signIn");
        this.$notify({
          type: "success",
          text: "Senha alterada com sucesso."
        });
      })
      .catch((e) => {
        if (e.code && e.code === "CodeMismatchException") {
          return this.$notify({
            type: "error",
            text: "Código de verificação inválido."
          });
        } else if (e.code && e.code === "LimitExceededException") {
          return this.$notify({
            type: "error",
            text: "Limite de tentativas excedido. Tente mais tarde."
          });
        }
        return this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(e)
        });
      })
      .finally(() => {
        this.loading = false;
        this.$refs.recaptchaForgotPasswordSubmit.reset();
      });
  }

  signIn() {
    AmplifyEventBus.$emit("authState", "signIn");
  }

  setError() {
    //pegar tradução
    //this.error = this.$Amplify.I18n.get(e.message || e);
  }

  get options() {
    const defaults = {
      email: ""
    };
    return Object.assign(defaults, this.forgotPasswordConfig || {});
  }

  get isFromOptions() {
    return this.sent || this.forgotPasswordConfig?.email;
  }
}
