
import { Vue, Component } from "vue-property-decorator";
import AdminGroupData from "@/components/company-registration/AdminGroupData.vue";
import CompanyData from "@/components/company-registration/CompanyData.vue";
import PartnersData from "@/components/company-registration/PartnersData.vue";
import Contract from "@/components/company-registration/Contract.vue";
import CheckEmail from "@/components/company-registration/CheckEmail.vue";
import WaitForApproval from "@/components/company-registration/WaitForApproval.vue";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";

interface ConfigsMap {
  adminGroupDataConfig: any;
  companyDataConfig: any;
  partnersDataConfig: PartnersDataConfig;
  contractConfig: ContractConfig;
  checkEmailConfig: any;
  waitForApprovalConfig: any;
}

export interface PartnersDataConfig {
  companyId?: number;
  adminGroupName?: string;
  adminGroupEmail?: string;
  adminGroupPhone?: string;
}

export interface ContractConfig {
  documentSignatureKey: string;
}

@Component({
  components: {
    AdminGroupData,
    CompanyData,
    PartnersData,
    Contract,
    CheckEmail,
    WaitForApproval
  }
})
export default class CompanyRegistration extends Vue {
  displayMap = {};
  static DEFAULT_CONFIGS_MAP: ConfigsMap = {
    adminGroupDataConfig: {},
    companyDataConfig: {},
    partnersDataConfig: {},
    contractConfig: { documentSignatureKey: "" },
    checkEmailConfig: {},
    waitForApprovalConfig: {}
  };
  configsMap: ConfigsMap = CompanyRegistration.DEFAULT_CONFIGS_MAP;
  static DEFAULT_STATE = "adminGroupData";

  mounted() {
    CompanyRegistrationEventBus.$on(
      "companyRegistrationState",
      (data: string, config?: any) => {
        this.displayMap = this.recreateDisplayMap(data);
        this.configsMap = this.recreateConfigs(config);
      }
    );

    // Initial state
    CompanyRegistrationEventBus.$emit(
      "companyRegistrationState",
      CompanyRegistration.DEFAULT_STATE
    );
  }

  recreateDisplayMap(state: string) {
    return {
      showAdminGroupData: state === "adminGroupData",
      showCompanyData: state === "companyData",
      showPartnersData: state === "partnersData",
      showContract: state === "contract",
      showCheckEmail: state === "checkEmail",
      showWaitForApproval: state === "waitForApproval"
    };
  }

  recreateConfigs(config?: any) {
    return {
      ...this.configsMap,
      ...(config?.adminGroupDataConfig
        ? {
            adminGroupDataConfig: {
              ...this.configsMap.adminGroupDataConfig,
              ...config?.adminGroupDataConfig
            }
          }
        : undefined),
      ...(config?.companyDataConfig
        ? {
            companyDataConfig: {
              ...this.configsMap.companyDataConfig,
              ...config?.companyDataConfig
            }
          }
        : undefined),
      ...(config?.partnersDataConfig
        ? {
            partnersDataConfig: {
              ...this.configsMap.partnersDataConfig,
              ...config?.partnersDataConfig
            }
          }
        : undefined),
      ...(config?.contractConfig
        ? {
            contractConfig: {
              ...this.configsMap.contractConfig,
              ...config?.contractConfig
            }
          }
        : undefined),
      ...(config?.checkEmailConfig
        ? {
            checkEmailConfig: {
              ...this.configsMap.checkEmailConfig,
              ...config?.checkEmailConfig
            }
          }
        : undefined),
      ...(config?.waitForApprovalConfig
        ? {
            waitForApprovalConfig: {
              ...this.configsMap.waitForApprovalConfig,
              ...config?.waitForApprovalConfig
            }
          }
        : undefined)
    };
  }

  get options() {
    return Object.assign(
      CompanyRegistration.DEFAULT_CONFIGS_MAP,
      this.configsMap || {}
    );
  }
}
