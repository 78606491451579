
import { Component, Vue, Prop } from "vue-property-decorator";
import AuthLayout from "@/components/core/layouts/AuthLayout.vue";
import AmplifyEventBus from "@/events/amplify-event-bus";

@Component({
  components: { AuthLayout }
})
export default class ExpiredPassword extends Vue {
  @Prop() expiredPasswordConfig: any;

  continueToPasswordReset(): void {
    AmplifyEventBus.$emit("authState", "forgotPassword");
    AmplifyEventBus.$emit("localUser", { email: this.options.email });
  }

  get options() {
    const defaults = {
      email: ""
    };
    return Object.assign(defaults, this.expiredPasswordConfig || {});
  }
}
