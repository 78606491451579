
import { Vue, Component, Prop } from "vue-property-decorator";
import CompanyRegistrationHeader from "@/components/company-registration/CompanyRegistrationHeader.vue";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";
import { ContractConfig } from "@/views/CompanyRegistration.vue";

@Component({
  components: {
    CompanyRegistrationHeader
  }
})
export default class Contract extends Vue {
  @Prop() config!: ContractConfig;
  loading: boolean = false;
  hasDisplayedContract: boolean = false;

  mounted(): void {
    const clicksignScript = document.createElement("script");
    clicksignScript.src = "/clicksign.js";

    document.body.appendChild(clicksignScript);

    clicksignScript.addEventListener("load", () => {
      this.prepareContract();
    });
  }

  prepareContract(): void {
    this.startLoading();
    const signatureKey = this.config.documentSignatureKey || "";

    this.displayContract(String(signatureKey));

    this.stopLoading();
  }

  displayContract(signatureKey: string): void {
    // Loads the embedded widget with request_signature_key
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-undef
    const widget = new Clicksign(signatureKey);

    // Defines the endpoint (https://sandbox.clicksign.com ou https://app.clicksign.com)
    widget.endpoint = process.env.VUE_APP_CLICKSIGN_BASE_URL;

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    widget.on("signed", function () {
      self.goToNextStep();
    });
    // Defines the origin URL (required in order to use it through a WebView)
    widget.origin = window.location.origin; // 'https://www.seusite.com.br'

    // Mounts the widget in the div
    widget.mount("signature-box");

    this.hasDisplayedContract = true;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  goToNextStep(): void {
    CompanyRegistrationEventBus.$emit(
      "companyRegistrationState",
      "waitForApproval"
    );
  }

  // goToPreviousStep(): void {
  //   CompanyRegistrationEventBus.$emit(
  //     "companyRegistrationState",
  //     "partnersData"
  //   );
  // }
}
