
import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Cookies from "js-cookie";
import CompanyRegistrationAdminData from "@/types/company-registration-admin-data";
import CompanyRegistrationHeader from "@/components/company-registration/CompanyRegistrationHeader.vue";
import CompanyRegistrationFooter from "@/components/company-registration/CompanyRegistrationFooter.vue";
import SaveButton from "@/components/SaveButton.vue";
import SafetyService from "@/services/safety-service";
import AmplifyEventBus from "@/events/amplify-event-bus";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";
import CompanyRegistration from "@/views/CompanyRegistration.vue";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import { INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE } from "@/utils/isValidPassword";
import CaptchaDisclaimer from "@/components/CaptchaDisclaimer.vue";
import { VueRecaptcha } from "vue-recaptcha";

const DEFAULT_PARTNER_ID_IDENTIFIER = "partner";

@Component({
  components: {
    ValidationObserver,
    CompanyRegistrationHeader,
    CompanyRegistrationFooter,
    SaveButton,
    CaptchaDisclaimer,
    VueRecaptcha
  }
})
export default class AdminGroupData extends Vue {
  $refs!: {
    recaptcha: HTMLFormElement;
  };

  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE =
    INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE;
  showPassword: boolean = false;
  loading: boolean = false;
  form = {
    responsibleName: "",
    responsibleEmail: "",
    responsiblePhone: "",
    responsiblePassword: "",
    responsiblePasswordConfirmation: ""
  } as CompanyRegistrationAdminData;
  safetyService: SafetyService;
  reCaptchaSiteKey = String(process.env.VUE_APP_RECAPTCHA_SITE_KEY);

  constructor() {
    super();
    this.safetyService = SafetyService.getInstance();
  }

  created(): void {
    this.setPartnerIdFromQueryParamsOrCookies();
  }

  onVerify(captchaToken: string): void {
    if (captchaToken) {
      this.save(captchaToken);
    }
  }

  onExpired(): void {
    this.$refs.recaptcha.reset();
  }

  onError(): void {
    this.$refs.recaptcha.reset();
  }

  onSubmit(): void {
    this.$refs.recaptcha.execute();
  }

  async save(captchaToken: string): Promise<void> {
    this.startLoading();

    const {
      responsibleName: name,
      responsiblePhone: phone,
      responsibleEmail: email,
      responsiblePassword: password
    } = this.form;
    const signUpData = { name, email, phone, password };
    await this.$store
      .dispatch("auth/signUpAdminGroup", { ...signUpData, captchaToken })
      .then(() => {
        this.goToVerifyAccountPage({
          adminGroupName: name,
          adminGroupEmail: email,
          adminGroupPhone: password
        });
      })
      .catch((error) => {
        this.stopLoading();

        const userAlreadyExists = error.response?.status === 409; // (Conflict)
        if (userAlreadyExists) {
          // User already exists, so we redirect to Login page
          this.$notify({
            type: "error",
            text: "Já existe um usuário com este e-mail. Se for seu, tente realizar login.",
            duration: 7500
          });
          return this.goToLoginPage();
        } else {
          // Another error has occurred (probably from Cognito)
          const errorMessage = error.response?.data?.message;
          // See https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors

          this.$notify({
            type: "error",
            text: `Ocorreu um erro ao criar a sua conta (${errorMessage}).`
          });
        }
      })
      .finally(() => {
        this.stopLoading();
        this.$refs.recaptcha.reset();
      });
  }

  setPartnerIdFromQueryParamsOrCookies(): void {
    const partnerId = this.getPartnerId();

    if (partnerId) {
      Cookies.set(DEFAULT_PARTNER_ID_IDENTIFIER, partnerId);
    }
  }

  getPartnerId(): string | undefined {
    const partnerIdFromCookies = this.getPartnerIdFromCookies();
    const partnerIdFromQueryParams = this.getPartnerIdFromQueryParams();
    if (partnerIdFromQueryParams) {
      return partnerIdFromQueryParams;
    } else if (partnerIdFromCookies) {
      this.$router.replace({
        query: { ...this.$route.query, partner: partnerIdFromCookies }
      });
      return partnerIdFromCookies;
    }
  }

  getPartnerIdFromQueryParams(): string | undefined {
    const partnerId = this.$route.query[
      DEFAULT_PARTNER_ID_IDENTIFIER
    ] as string;
    return partnerId;
  }

  getPartnerIdFromCookies(): string | undefined {
    const partnerId = Cookies.get(DEFAULT_PARTNER_ID_IDENTIFIER);
    return partnerId;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  async goToLoginPage(): Promise<void> {
    await this.$router.push("/auth");
    AmplifyEventBus.$emit("authState", "signIn");
  }

  async goToVerifyAccountPage(data: {
    adminGroupName: string;
    adminGroupEmail: string;
    adminGroupPhone: string;
  }): Promise<void> {
    const { adminGroupName, adminGroupEmail, adminGroupPhone } = data;
    await this.$router.push("/auth");
    AmplifyEventBus.$emit("localUser", { email: this.form.responsibleEmail });
    AmplifyEventBus.$emit("authState", "confirmSignUp");
    CompanyRegistrationEventBus.$emit(
      "companyRegistrationState",
      CompanyRegistration.DEFAULT_STATE,
      {
        partnersDataConfig: { adminGroupName, adminGroupEmail, adminGroupPhone }
      }
    );
  }
}
