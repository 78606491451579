
import { Vue, Component } from "vue-property-decorator";
import PartnerAdminData from "@/components/partner-registration/PartnerAdminData.vue";
import PartnerData from "@/components/partner-registration/PartnerData.vue";
import WaitForApproval from "@/components/partner-registration/WaitForApproval.vue";
import PartnerRegistrationEventBus from "@/events/partner-registration-event-bus";

interface ConfigsMap {
  partnerAdminDataConfig: any;
  partnerDataConfig: any;
  waitForApprovalConfig: any;
}

export interface PartnersDataConfig {
  partnerId?: number;
  partnerAdminName?: string;
  partnerAdminEmail?: string;
  partnerAdminPhone?: string;
}

export interface ContractConfig {
  documentSignatureKey: string;
}

@Component({
  components: {
    PartnerAdminData,
    PartnerData,
    WaitForApproval
  }
})
export default class PartnerRegistration extends Vue {
  displayMap = {};
  static DEFAULT_CONFIGS_MAP: ConfigsMap = {
    partnerAdminDataConfig: {},
    partnerDataConfig: {},
    waitForApprovalConfig: {}
  };
  configsMap: ConfigsMap = PartnerRegistration.DEFAULT_CONFIGS_MAP;
  static DEFAULT_STATE = "partnerAdminData";

  mounted() {
    PartnerRegistrationEventBus.$on(
      "partnerRegistrationState",
      (data: string, config?: any) => {
        this.displayMap = this.recreateDisplayMap(data);
        this.configsMap = this.recreateConfigs(config);
      }
    );

    // Initial state
    PartnerRegistrationEventBus.$emit(
      "partnerRegistrationState",
      PartnerRegistration.DEFAULT_STATE
    );
  }

  recreateDisplayMap(state: string) {
    return {
      showPartnerAdminData: state === "partnerAdminData",
      showPartnerData: state === "partnerData",
      showWaitForApproval: state === "waitForApproval"
    };
  }

  recreateConfigs(config?: any) {
    return {
      ...this.configsMap,
      ...(config?.partnerAdminDataConfig
        ? {
            partnerAdminDataConfig: {
              ...this.configsMap.partnerAdminDataConfig,
              ...config?.partnerAdminDataConfig
            }
          }
        : undefined),
      ...(config?.partnerDataConfig
        ? {
            partnerDataConfig: {
              ...this.configsMap.partnerDataConfig,
              ...config?.partnerDataConfig
            }
          }
        : undefined),
      ...(config?.waitForApprovalConfig
        ? {
            waitForApprovalConfig: {
              ...this.configsMap.waitForApprovalConfig,
              ...config?.waitForApprovalConfig
            }
          }
        : undefined)
    };
  }

  get options() {
    return Object.assign(
      PartnerRegistration.DEFAULT_CONFIGS_MAP,
      this.configsMap || {}
    );
  }
}
