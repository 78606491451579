
import { Component, Vue, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import AuthLayout from "@/components/core/layouts/AuthLayout.vue";
import CaptchaDisclaimer from "@/components/CaptchaDisclaimer.vue";
import AmplifyEventBus from "@/events/amplify-event-bus";
import { VueRecaptcha } from "vue-recaptcha";

@Component({
  components: {
    ValidationObserver,
    AuthLayout,
    VueRecaptcha,
    CaptchaDisclaimer
  }
})
export default class ConfirmSignUp extends Vue {
  @Prop() confirmSignUpConfig: any;

  $refs!: {
    recaptchaConfirmSignup: HTMLFormElement;
    recaptchaResendSignup: HTMLFormElement;
  };

  email: string;
  code: string;
  loading: boolean;
  reCaptchaSiteKey = String(process.env.VUE_APP_RECAPTCHA_SITE_KEY);

  constructor() {
    super();

    this.email = "";
    this.code = "";
    this.loading = false;
  }

  onVerifyConfirmSignup(captchaToken: string): void {
    if (captchaToken) {
      this.confirmSignUp(captchaToken);
    }
  }

  onExpiredConfirmSignup(): void {
    this.$refs.recaptchaConfirmSignup.reset();
  }

  onErrorConfirmSignup(): void {
    this.$refs.recaptchaConfirmSignup.reset();
  }

  onSubmitConfirmSignup(): void {
    this.$refs.recaptchaConfirmSignup.execute();
  }

  onVerifyResendSignup(captchaToken: string): void {
    if (captchaToken) {
      this.resendSignUp(captchaToken);
    }
  }

  onExpiredResendSignup(): void {
    this.$refs.recaptchaResendSignup.reset();
  }

  onErrorResendignup(): void {
    this.$refs.recaptchaResendSignup.reset();
  }

  onSubmitResendSignup(): void {
    this.$refs.recaptchaResendSignup.execute();
  }

  async resendSignUp(captchaToken: string): Promise<void> {
    this.loading = true;
    await this.$store
      .dispatch("auth/resendSignUp", {
        email: this.options.email,
        captchaToken
      })
      .then(() => {
        this.$notify({
          type: "success",
          text: "Código para confirmação de registro enviado para o e-mail."
        });
      })
      .catch(() => {
        this.$notify({
          type: "error",
          text: "Erro ao enviar código de verificação de conta."
        });
      })
      .finally(() => {
        this.loading = false;
        this.$refs.recaptchaResendSignup.reset();
      });
  }

  async confirmSignUp(captchaToken: string): Promise<void> {
    this.loading = true;
    await this.$store
      .dispatch("auth/confirmSignUp", {
        email: this.options.email,
        code: this.code,
        captchaToken
      })
      .then(() => {
        this.$notify({
          type: "success",
          text: "Conta confirmada com sucesso. Realize login."
        });
        return AmplifyEventBus.$emit("authState", "signIn");
      })
      .catch(() => {
        return this.$notify({
          type: "error",
          text: "Erro ao confirmar verificação de conta."
        });
      })
      .finally(() => {
        this.loading = false;
        this.$refs.recaptchaConfirmSignup.reset();
      });
  }

  signIn() {
    AmplifyEventBus.$emit("authState", "signIn");
  }

  get options() {
    const defaults = {
      email: ""
    };
    return Object.assign(defaults, this.confirmSignUpConfig || {});
  }
}
