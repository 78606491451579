
import { Vue, Component, Prop } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import store from "@/store";
import CompanyRegistrationHeader from "@/components/company-registration/CompanyRegistrationHeader.vue";
import CompanyRegistrationFooter from "@/components/company-registration/CompanyRegistrationFooter.vue";
import SaveButton from "@/components/SaveButton.vue";
import CompanyDocumentUpload from "@/components/company-registration/CompanyDocumentUpload.vue";
import CompanyRepresentative, {
  CompanyRepresentativeType
} from "@/types/company-representative";
import CompanyService from "@/services/company-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";
import CompanyDocument from "@/types/company-document";
import dayjs from "@/plugins/day-js";
import { PartnersDataConfig } from "@/views/CompanyRegistration.vue";
import removeCountryCodeFromPhone from "@/utils/removeCountryCodeFromPhone";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

type CompanyRepresentativeItem = CompanyRepresentative & {
  index?: number | null;
};

@Component({
  components: {
    ValidationObserver,
    CompanyRegistrationHeader,
    CompanyRegistrationFooter,
    SaveButton,
    CompanyDocumentUpload
  }
})
export default class PartnersData extends Vue {
  @Prop() config!: PartnersDataConfig;
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  showPassword: boolean = false;
  loading: boolean = false;
  companyId: number;
  companyDocuments: CompanyDocument[];
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL || "";
  form = {
    isPartner: false,
    isProcurator: false,
    isNeitherPartnerNorProcurator: false,
    procurator: {} as CompanyRepresentative,
    companyPartners: [] as CompanyRepresentativeItem[]
  };
  companyService: CompanyService;

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.companyId = this.config.companyId || 0;
    this.companyDocuments = [];
  }

  async created(): Promise<void> {
    this.loading = true;
    await this.loadDocuments();
    await this.loadCompanyRepresentatives();
    this.loading = false;
  }

  loadCompanyRepresentatives = async (): Promise<void> => {
    const [companyRepresentativesError, companyRepresentatives] =
      await this.companyService.listCompanyRepresentativesForCompany(
        this.companyId
      );
    if (!companyRepresentativesError && companyRepresentatives) {
      const procurator = companyRepresentatives.find(
        (companyRepresentative) =>
          companyRepresentative.type === CompanyRepresentativeType.PROCURATOR
      );

      if (procurator) {
        let birthDate = "";
        if (procurator.birthDate) {
          birthDate = dayjs(procurator.birthDate).format("DD/MM/YYYY");
        }
        if (procurator.document && procurator.document.url) {
          procurator.documentUrl = procurator.document.url;
        }
        this.form.procurator = {
          ...procurator,
          birthDate
        };
        this.form.isProcurator = true;
      }

      const authenticatedUser = store.getters["auth/authenticatedUser"];

      this.form.companyPartners = companyRepresentatives
        .filter((companyRepresentative) => {
          return (
            companyRepresentative.type === CompanyRepresentativeType.PARTNER
          );
        })
        .map((companyRepresentative, index) => {
          let birthDate = "";
          if (companyRepresentative.birthDate) {
            birthDate = dayjs(companyRepresentative.birthDate).format(
              "DD/MM/YYYY"
            );
          }
          if (
            companyRepresentative.document &&
            companyRepresentative.document.url
          ) {
            companyRepresentative.documentUrl =
              companyRepresentative.document.url;
          }
          return {
            ...companyRepresentative,
            birthDate,
            index
          };
        });
      if (
        !this.form.isProcurator &&
        this.form.companyPartners.find(
          (p) => p.email === authenticatedUser.email
        )
      ) {
        this.form.isPartner = true;
      }
    }
  };

  loadDocuments = async (): Promise<void> => {
    const [companyDocumentsError, companyDocuments] =
      await this.companyService.listCompanyDocuments(this.companyId);

    if (companyDocuments && !companyDocumentsError) {
      this.companyDocuments.length = 0;
      companyDocuments?.forEach((companyDocument) => {
        this.companyDocuments.push(companyDocument);
      });
    }
  };

  createDocument = async (data: {
    companyId: number;
    type: string;
    url: string;
    companyRepresentativeId?: number;
  }): Promise<void> => {
    await this.companyService.createOrUpdateCompanyDocument(data);
    await this.loadDocuments();
  };

  currentDocument(
    type: string,
    companyRepresentativeId?: number
  ): CompanyDocument | undefined {
    return this.companyDocuments.find(
      (companyDocument) =>
        companyDocument.type.name === type &&
        (!companyRepresentativeId ||
          companyDocument.companyRepresentativeId === companyRepresentativeId)
    );
  }

  handleIsPartnerToggle(value: boolean): void {
    this.eraseProcurator();
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.form.isProcurator = false;
      this.form.isNeitherPartnerNorProcurator = false;
      this.copyAdminGroupDataToCompanyRepresentative();
    }
  }

  handleIsProcuratorToggle(value: boolean): void {
    this.eraseProcurator();
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.form.isPartner = false;
      this.form.isNeitherPartnerNorProcurator = false;
      this.copyAdminGroupDataToProcurator();
    }
  }

  handleIsNeitherPartnerNorProcuratorToggle(value: boolean): void {
    this.eraseProcurator();
    this.eraseCompanyRepresentatives();
    if (value === true) {
      this.form.isPartner = false;
      this.form.isProcurator = false;
      this.form.companyPartners = [{} as CompanyRepresentativeItem];
    }
  }

  async copyAdminGroupDataToProcurator(): Promise<void> {
    this.form.procurator = {
      ...this.form.procurator,
      name: this.config.adminGroupName || "",
      email: this.config.adminGroupEmail || "",
      phone: this.config.adminGroupPhone
        ? removeCountryCodeFromPhone(this.config.adminGroupPhone)
        : ""
    } as CompanyRepresentativeItem;
  }

  async copyAdminGroupDataToCompanyRepresentative(): Promise<void> {
    this.form.companyPartners = [
      {
        name: this.config.adminGroupName || "",
        email: this.config.adminGroupEmail || "",
        phone: this.config.adminGroupPhone
          ? removeCountryCodeFromPhone(this.config.adminGroupPhone)
          : ""
      } as CompanyRepresentativeItem
    ];
  }

  async eraseProcurator(): Promise<void> {
    this.form.procurator = {} as CompanyRepresentativeItem;
  }

  async eraseCompanyRepresentatives(): Promise<void> {
    this.form.companyPartners = [];
  }

  async save(): Promise<void> {
    this.startLoading();

    // just to be sure
    if (
      (this.form.isPartner || this.form.isNeitherPartnerNorProcurator) &&
      this.form.companyPartners.length === 0
    ) {
      this.stopLoading();
      this.$notify({
        title: "Erro ao salvar dados dos sócios",
        type: "error",
        text: "Informe pelo menos um sócio"
      });
      return;
    }

    if (this.form.isProcurator && !this.form.procurator.documentUrl) {
      this.stopLoading();
      this.$notify({
        title: "Erro ao salvar dados do procurador",
        type: "error",
        text: "Você precisa anexar o documento do procurador"
      });
      return;
    }

    if (!this.currentDocument("CONTRATO")) {
      this.stopLoading();
      this.$notify({
        title: "Erro ao salvar os dados",
        type: "error",
        text: "Você precisa enviar o contrato social da empresa"
      });
      return;
    }

    const companyPartnerWithoutDocumentUrl = this.form.companyPartners.find(
      (companyPartner) => !companyPartner.documentUrl
    );

    if (companyPartnerWithoutDocumentUrl) {
      this.stopLoading();
      this.$notify({
        title: "Erro ao salvar dados dos sócios",
        type: "error",
        text: "Você precisa anexar o documento de todos os sócios"
      });
      return;
    }
    // an e-mail that belongs to a partner/procurator can NOT be used as e-mail for other company partners,
    // since we have to be able to identify the partner/procurator on the backend in order to get their
    // document signature key
    if (this.form.isPartner || this.form.isProcurator) {
      const companyRepresentatives = [
        ...(this.form.procurator.cpf ? [this.form.procurator] : []),
        ...this.form.companyPartners
      ];
      const companyRepresentativesWithSameEmailAsAdmin =
        companyRepresentatives.filter(
          (cp) => cp.email === this.config.adminGroupEmail
        );
      if (companyRepresentativesWithSameEmailAsAdmin.length > 1) {
        this.stopLoading();
        this.$notify({
          title: "Erro ao salvar dados dos sócios",
          type: "error",
          text: "Você não pode usar o seu e-mail como forma de contato dos demais sócios."
        });
      }
    }

    const formattedCompanyProcurator = {
      ...this.form.procurator,
      type: CompanyRepresentativeType.PROCURATOR,
      birthDate: dayjs(this.form.procurator.birthDate, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      )
    };
    const formattedCompanyPartners = this.form.companyPartners.map((cp) => ({
      ...cp,
      type: CompanyRepresentativeType.PARTNER,
      birthDate: dayjs(cp.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD")
    }));
    const formattedCompanyRepresentatives = [
      ...(formattedCompanyProcurator.cpf ? [formattedCompanyProcurator] : []),
      ...formattedCompanyPartners
    ];

    const [companyRepresentativesError, companyRepresentatives] =
      await this.companyService.createOrUpdateCompanyRepresentatives({
        companyId: this.companyId,
        companyRepresentatives: formattedCompanyRepresentatives
      });

    if (companyRepresentativesError) {
      this.stopLoading();
      const message = getErrorMessageFromApiError(companyRepresentativesError);
      this.$notify({
        title: "Erro ao salvar dados dos sócios",
        type: "error",
        text: message
      });
      return;
    }

    if (companyRepresentatives) {
      for (const companyRepresentative of companyRepresentatives) {
        let documentUrl = "";
        let documentType = "";

        if (companyRepresentative.type === CompanyRepresentativeType.PARTNER) {
          const companyPartner = this.form.companyPartners.find(
            (cp) =>
              cp.cpf.replace(/\D/g, "") === companyRepresentative.cpf.toString()
          );

          documentUrl = companyPartner?.documentUrl || "";
          documentType = "DOCUMENTO_SOCIO";
        } else if (
          companyRepresentative.type === CompanyRepresentativeType.PROCURATOR
        ) {
          documentUrl = this.form.procurator.documentUrl || "";
          documentType = "DOCUMENTO_PROCURADOR";
        }

        if (documentUrl && documentType) {
          this.createDocument({
            companyId: this.companyId,
            companyRepresentativeId: companyRepresentative.id,
            type: documentType,
            url: documentUrl
          });
        }
      }
    }

    let documentSignatureKey;
    if (this.form.isPartner || this.form.isProcurator) {
      const [adminDocumentSignatureKeyError, adminDocumentSignatureKey] =
        await this.companyService.getDocumentSignatureKeyForCurrentUser({
          companyId: this.companyId
        });

      if (adminDocumentSignatureKeyError) {
        this.stopLoading();
        const message = getErrorMessageFromApiError(
          companyRepresentativesError
        );
        this.$notify({
          title: "Erro ao buscar a chave para assinatura",
          type: "error",
          text: message
        });
        return;
      }

      documentSignatureKey = adminDocumentSignatureKey!;
    }

    this.goToNextStep({ documentSignatureKey });
    this.stopLoading();
  }

  addAnotherCompanyPartner(): void {
    this.form.companyPartners.push({} as CompanyRepresentative);
  }

  removeCompanyParnerAtIndex(index: number): void {
    this.form.companyPartners.splice(index, 1);
  }

  goToNextStep({
    documentSignatureKey
  }: {
    documentSignatureKey?: string;
  }): void {
    if (documentSignatureKey) {
      CompanyRegistrationEventBus.$emit(
        "companyRegistrationState",
        "contract",
        { contractConfig: { documentSignatureKey } }
      );
    } else {
      CompanyRegistrationEventBus.$emit(
        "companyRegistrationState",
        "checkEmail"
      );
    }
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  get hasSelectedAnOption() {
    return (
      this.form.isPartner ||
      this.form.isProcurator ||
      this.form.isNeitherPartnerNorProcurator
    );
  }

  get numberOfRepresentatives() {
    return (this.form.isProcurator ? 1 : 0) + this.form.companyPartners.length;
  }

  get hasReachedRepresentativesLimit() {
    return this.numberOfRepresentatives >= 2;
  }
}
