
import { Vue, Component } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import Cookies from "js-cookie";
import PartnerRegistrationAdminData from "@/types/partner-registration-admin-data";
import PartnerRegistrationHeader from "@/components/partner-registration/PartnerRegistrationHeader.vue";
import PartnerRegistrationFooter from "@/components/partner-registration/PartnerRegistrationFooter.vue";
import SaveButton from "@/components/SaveButton.vue";
import SafetyService from "@/services/safety-service";
import AmplifyEventBus from "@/events/amplify-event-bus";
import PartnerRegistrationEventBus from "@/events/partner-registration-event-bus";
import PartnerRegistration from "@/views/PartnerRegistration.vue";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";
import { INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE } from "@/utils/isValidPassword";
import { VueRecaptcha } from "vue-recaptcha";
import CaptchaDisclaimer from "@/components/CaptchaDisclaimer.vue";

const DEFAULT_PARTNER_ID_IDENTIFIER = "partner";

@Component({
  components: {
    ValidationObserver,
    PartnerRegistrationHeader,
    PartnerRegistrationFooter,
    SaveButton,
    VueRecaptcha,
    CaptchaDisclaimer
  }
})
export default class PartnerAdminData extends Vue {
  $refs!: {
    recaptcha: HTMLFormElement;
  };

  showPassword: boolean = false;
  loading: boolean = false;
  form = {
    name: "",
    email: "",
    phone: "",
    password: "",
    passwordConfirmation: "",
    captchaToken: ""
  } as PartnerRegistrationAdminData;
  safetyService: SafetyService;
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;
  INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE =
    INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE;
  reCaptchaSiteKey = String(process.env.VUE_APP_RECAPTCHA_SITE_KEY);

  constructor() {
    super();
    this.safetyService = SafetyService.getInstance();
  }

  created(): void {
    this.setPartnerIdFromQueryParamsOrCookies();
  }

  onVerify(captchaToken: string): void {
    if (captchaToken) {
      this.save(captchaToken);
    }
  }

  onExpired(): void {
    this.$refs.recaptcha.reset();
  }

  onError(): void {
    this.$refs.recaptcha.reset();
  }

  onSubmit(): void {
    this.$refs.recaptcha.execute();
  }

  async save(captchaToken: string): Promise<void> {
    this.startLoading();

    const {
      name: name,
      phone: phone,
      email: email,
      password: password
    } = this.form;
    const signUpData = { name, email, phone, password };
    await this.$store
      .dispatch("auth/signUpPartnerAdmin", { ...signUpData, captchaToken })
      .then(() => {
        this.goToVerifyAccountPage({
          name,
          email,
          phone
        });
      })
      .catch((error) => {
        this.stopLoading();

        const userAlreadyExists = error.response?.status === 409; // (Conflict)
        if (userAlreadyExists) {
          // User already exists, so we redirect to Login page
          this.$notify({
            type: "error",
            text: "Já existe um usuário com este e-mail. Se for seu, tente realizar login.",
            duration: 7500
          });
          return this.goToLoginPage();
        } else {
          // Another error has occurred (probably from Cognito)
          const errorMessage = error.response?.data?.message;
          // See https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_SignUp.html#API_SignUp_Errors

          this.$notify({
            type: "error",
            text: `Ocorreu um erro ao criar a sua conta (${errorMessage}).`
          });
        }
      })
      .finally(() => {
        this.stopLoading();
        this.$refs.recaptcha.reset();
      });
  }

  setPartnerIdFromQueryParamsOrCookies(): void {
    const partnerId =
      this.getPartnerIdFromQueryParams() || this.getPartnerIdFromCookies();

    if (partnerId) {
      Cookies.set(DEFAULT_PARTNER_ID_IDENTIFIER, partnerId);
    }
  }

  getPartnerId(): string | undefined {
    const partnerIdFromCookies = this.getPartnerIdFromCookies();
    const partnerIdFromQueryParams = this.getPartnerIdFromQueryParams();
    if (partnerIdFromQueryParams) {
      return partnerIdFromQueryParams;
    } else if (partnerIdFromCookies) {
      this.$router.replace({
        query: { ...this.$route.query, partner: partnerIdFromCookies }
      });
      return partnerIdFromCookies;
    }
  }

  getPartnerIdFromQueryParams(): string | undefined {
    const partnerId = this.$route.query[
      DEFAULT_PARTNER_ID_IDENTIFIER
    ] as string;
    return partnerId;
  }

  getPartnerIdFromCookies(): string | undefined {
    const partnerId = Cookies.get(DEFAULT_PARTNER_ID_IDENTIFIER);
    return partnerId;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  async goToLoginPage(): Promise<void> {
    await this.$router.push("/auth");
    AmplifyEventBus.$emit("authState", "signIn");
  }

  get borrowerPortalUrl() {
    return process.env.VUE_APP_BORROWER_PORTAL_URL;
  }

  async goToVerifyAccountPage(data: {
    name: string;
    email: string;
    phone: string;
  }): Promise<void> {
    const { name, email, phone } = data;
    await this.$router.push("/auth");
    AmplifyEventBus.$emit("localUser", { email: this.form.email });
    AmplifyEventBus.$emit("authState", "confirmSignUp");
    PartnerRegistrationEventBus.$emit(
      "partnerRegistrationState",
      PartnerRegistration.DEFAULT_STATE,
      {
        partnersDataConfig: { name, email, phone }
      }
    );
  }
}
