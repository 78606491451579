import Company from "./company";
import CompanyDocument from "./company-document";

export default interface CompanyRepresentative {
  id: number;
  company: Company;
  companyId: number;
  type: CompanyRepresentativeType;
  name: string;
  cpf: string;
  phone: string;
  email: string;
  birthDate: string;
  document: CompanyDocument;
  documentUrl?: string;
}

export enum CompanyRepresentativeType {
  PARTNER = "PARTNER",
  PROCURATOR = "PROCURATOR"
}
