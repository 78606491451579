
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import AuthLayout from "@/components/core/layouts/AuthLayout.vue";
import CaptchaDisclaimer from "@/components/CaptchaDisclaimer.vue";
import { mapGetters } from "vuex";
import AmplifyEventBus from "@/events/amplify-event-bus";
import CompanyService from "@/services/company-service";
import store from "@/store";
import CompanyRegistrationEventBus from "@/events/company-registration-event-bus";
import { UserFlag } from "@/types/user";
import PartnerService from "@/services/partner-service";
import PartnerRegistrationEventBus from "@/events/partner-registration-event-bus";
import { VueRecaptcha } from "vue-recaptcha";

@Component({
  components: { ValidationObserver, AuthLayout, VueRecaptcha, CaptchaDisclaimer },
  computed: {
    ...mapGetters({ isAuthenticated: "auth/isAuthenticated" })
  }
})
export default class SignIn extends Vue {
  $refs!: {
    form: HTMLFormElement;
    obs: HTMLFormElement;
    recaptcha: HTMLFormElement;
  };

  email: string;
  password: string;
  loading: boolean;
  isAuthenticated!: boolean;
  error: string;
  companyService: CompanyService;
  partnerService: PartnerService;
  reCaptchaSiteKey = String(process.env.VUE_APP_RECAPTCHA_SITE_KEY);

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.partnerService = PartnerService.getInstance();
    this.email = "";
    this.password = "";
    this.error = "";
    this.loading = false;
  }

  onVerify(captchaToken: string): void {
    if (captchaToken) {
      this.handleLogin(captchaToken);
    }
  }

  onExpired(): void {
    this.$refs.recaptcha.reset();
  }

  onError(): void {
    this.$refs.recaptcha.reset();
  }

  onSubmit(): void {
    this.$refs.recaptcha.execute();
  }

  async handleLogin(captchaToken: string): Promise<void> {
    this.loading = true;
    this.$store
      .dispatch("auth/signIn", {
        email: this.email,
        password: this.password,
        captchaToken
      })
      .then(async (data) => {
        this.$notify({
          type: "success",
          text: "Login realizado com sucesso."
        });

        if (this.authenticatedUser.type === "ADMIN_GROUP") {
          const adminGroupId = this.authenticatedUser.id;
          const [
            adminGroupHasFinishedCompanyRegisterError,
            adminGroupHasFinishedCompanyRegister
          ] = await this.companyService.checkIfAdminGroupHasFinishedCompanyRegister(
            adminGroupId
          );

          if (adminGroupHasFinishedCompanyRegisterError) {
            this.$notify({
              type: "error",
              text: "Não foi possível verificar o cadastro da empresa."
            });
            return;
          }

          // Admin group has not finished company register yet
          if (!adminGroupHasFinishedCompanyRegister) {
            const {
              name: adminGroupName,
              email: adminGroupEmail,
              phone: adminGroupPhone
            } = this.authenticatedUser;
            await this.$router.push({ name: "CompanyRegistration" });
            CompanyRegistrationEventBus.$emit(
              "companyRegistrationState",
              "companyData",
              {
                partnersDataConfig: {
                  adminGroupName,
                  adminGroupEmail,
                  adminGroupPhone
                }
              }
            );
            return;
          }

          // Admin group has finished company register but hasn't been approved yet
          if (
            adminGroupHasFinishedCompanyRegister &&
            this.authenticatedUser.flag === UserFlag.LIMITED
          ) {
            await this.$router.push({ name: "CompanyRegistration" });
            CompanyRegistrationEventBus.$emit(
              "companyRegistrationState",
              "waitForApproval"
            );
            return;
          }
        } else if (this.authenticatedUser.type === "PARTNER") {
          const [loggedPartnerError, loggedPartner] =
            await this.partnerService.getLoggedPartner();

          if (loggedPartner) {
            store.commit("auth/setLoggedPartnerId", loggedPartner.id);
            store.commit(
              "auth/setLoggedPartnerIdentifier",
              loggedPartner.identifier?.identifier
            );
          }
          this.$router.push({ name: "Home" });
        } else if (this.authenticatedUser.type === "PARTNER_MASTER") {
          // const partnerAdminId = this.authenticatedUser.id;
          const [loggedPartnerError, loggedPartner] =
            await this.partnerService.getLoggedPartner();

          if (
            (loggedPartnerError &&
              loggedPartnerError.response.data.message ===
                "Recurso não encontrado.") ||
            !loggedPartner
          ) {
            const {
              name: partnerAdminName,
              email: partnerAdminEmail,
              phone: partnerAdminPhone
            } = this.authenticatedUser;
            await this.$router.push({ name: "PartnerRegistration" });
            PartnerRegistrationEventBus.$emit(
              "partnerRegistrationState",
              "partnerData",
              {
                partnersDataConfig: {
                  partnerAdminName,
                  partnerAdminEmail,
                  partnerAdminPhone
                }
              }
            );
            return;
          } else if (this.authenticatedUser.flag === UserFlag.LIMITED) {
            await this.$router.push({ name: "PartnerRegistration" });
            PartnerRegistrationEventBus.$emit(
              "partnerRegistrationState",
              "waitForApproval"
            );
            return;
          }

          store.commit("auth/setLoggedPartnerId", loggedPartner.id);
          store.commit(
            "auth/setLoggedPartnerIdentifier",
            loggedPartner.identifier?.identifier
          );
        }

        this.$router.push({ name: "Home" });
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.message;
        const exception = e.response?.data?.exception;

        if (errorMessage === "NOT_AUTHORIZED_EXCEPTION") {
          return this.$notify({
            type: "error",
            text: "Dados de acesso inválidos."
          });
        } else if (errorMessage === "EXPIRED_PASSWORD") {
          AmplifyEventBus.$emit("authState", "expiredPassword");
          AmplifyEventBus.$emit("localUser", { email: this.email });
          return;
        } else if (exception === "RateLimitException") {
          return this.$notify({
            type: "warning",
            text: "Excesso de tentativas realizadas. Tente novamente mais tarde.."
          });
        } else if (errorMessage === "NewPasswordRequired") {
          this.$notify({
            type: "warning",
            text: "Antes de prosseguir, atualize os dados necessários."
          });
          AmplifyEventBus.$emit("authState", "newPassword");

          AmplifyEventBus.$emit("localUser", { email: this.email });
          return;
        } else if (errorMessage === "NotAuthorizedException") {
          return this.$notify({
            type: "error",
            text: "Usuário ou senha inválidos."
          });
        } else if (errorMessage === "UserNotConfirmedException") {
          AmplifyEventBus.$emit("localUser", { email: this.email });
          AmplifyEventBus.$emit("authState", "confirmSignUp");
        } else if (errorMessage === "PasswordResetRequiredException") {
          AmplifyEventBus.$emit("localUser", { email: this.email });
          AmplifyEventBus.$emit("authState", "forgotPassword");
          return this.$notify({
            type: "warning",
            text: "É necessária a alteração da senha."
          });
        } else {
          return this.$notify({
            type: "error",
            text: "Ocorreu um erro ao realizar o login."
          });
        }
      })
      .finally(() => {
        this.loading = false;
        this.$refs.recaptcha.reset();
      });
  }

  get authenticatedUser() {
    return store.getters["auth/authenticatedUser"];
  }

  forgotPassword() {
    AmplifyEventBus.$emit("authState", "forgotPassword");
  }

  setError(e: any) {
    //pegar tradução
    //this.error = this.$Amplify.I18n.get(e.message || e);
  }
}
