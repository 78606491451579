import { render, staticRenderFns } from "./PartnerRegistrationHeader.vue?vue&type=template&id=4bb32a18&scoped=true"
var script = {}
import style0 from "./PartnerRegistrationHeader.vue?vue&type=style&index=0&id=4bb32a18&prod&lang=scss"
import style1 from "./PartnerRegistrationHeader.vue?vue&type=style&index=1&id=4bb32a18&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bb32a18",
  null
  
)

export default component.exports